import { createIcon } from '@chakra-ui/icons';

const SatelliteDish = createIcon({
	displayName: 'SatelliteDish',
	viewBox: '0 0 24 24',
	path: [
		<path
			d="M14.475 11.4247C14.6143 11.2854 14.7247 11.12 14.8001 10.938C14.8755 10.756 14.9143 10.561 14.9143 10.364C14.9143 10.167 14.8755 9.97196 14.8001 9.78997C14.7247 9.60798 14.6143 9.44262 14.475 9.30334C14.3357 9.16405 14.1703 9.05356 13.9883 8.97818C13.8063 8.90279 13.6113 8.864 13.4143 8.864C13.2173 8.864 13.0223 8.90279 12.8403 8.97818C12.6583 9.05356 12.4929 9.16405 12.3536 9.30334L13.4143 10.364L14.475 11.4247Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		></path>,
		<path
			d="M8.05887 15.9411C7.40613 15.2883 6.88835 14.5134 6.53508 13.6606C6.18182 12.8077 6 11.8936 6 10.9705C6 10.0474 6.18182 9.13331 6.53508 8.28046C6.88835 7.42761 7.40613 6.65269 8.05888 5.99995L18 15.9411C17.3473 16.5938 16.5723 17.1116 15.7195 17.4649C14.8666 17.8181 13.9526 17.9999 13.0294 17.9999C12.1063 17.9999 11.1922 17.8181 10.3394 17.4649C9.48654 17.1116 8.71162 16.5938 8.05887 15.9411ZM8.05887 15.9411L5 21H10M17.6025 9.0463C17.1056 7.87566 16.1813 6.93835 15.0177 6.42515M20.6901 8.79485C20.3187 7.49954 19.6261 6.31904 18.6766 5.36288C17.7271 4.40672 16.5514 3.7059 15.2587 3.32544"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		></path>,
	],
	defaultProps: { boxSize: 4 },
});

export default SatelliteDish;
